<template>
    <main class="o-main o-main--page o-main--content" id="main">
        <article class="c-article c-article--page o-container" :class="[{ 'o-container--full' : currPage.template === 'page-full.php' }, `o-container--${currPage.slug}`]" v-if="currPage">
            <h1 class="h2 c-article__title c-article__title--page">{{ currPage.post_title }}</h1>
            <page-widget :id="`widget-${i}`" :currPage="currPage" v-for="(widget, i) in currPage.page_builder"
                         :data="widget" :key="`${currPage.id}_${i}`"></page-widget>
        </article>
    </main>
</template>

<script>
    import { mapGetters } from 'vuex'
    import api from '@/api'

    import pageWidget from '@/components/page/Widget.vue'

    export default {
        name: 'Page',

        data () {
          return {
              currPage: null,
              err: null,
              loaded: false
          }
        },

        beforeMount () {
            api.getPage(this.pageID, page => {
                this.currPage = page
                document.title = `${this.currPage.post_title} | Rheiner`
            })
        },

        computed: {
            ...mapGetters({
                allPages: 'allPages',
            }),

            pageID () {
                const pageSlug = this.$route.params.pageSlug
                const findPage = page => page.slug === pageSlug
                const page = this.allPages.find(findPage)
                return page.id
            }
        },

        components: {
            pageWidget
        }
    }
</script>

<style lang="scss" src="@/styles/components/_article.scss"></style>